<template>
  <el-select
    style="width: 100%"
    v-model="value"
    placeholder="請選擇"
    value-key="option_id"
    @change="
      $emit(
        'handleSelected',
        questionData,
        value.option_id,
        questionBlock,
        value
      )
    "
  >
    <el-option
      v-for="(item, index) in questionData.option_array"
      :key="index"
      :label="item.option_title"
      :value="item"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      value: '',
    };
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

/deep/ .el-select-dropdown__item {
  white-space: normal;
  overflow: unset;
  height: auto;
}
</style>
