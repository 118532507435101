import {
  getQuestionnaireList,
  extendQuestionnaire,
  getRetakeCount,
  getPushCount,
  resendPush,
  getPushList,
} from '@/api/questionnaire';
import { Message } from 'element-ui';

const state = {
  activeTab: '1',
  currentQuestionnaire: {},
  changeInfo: false,
  list: [],
  list_count: 0,
  page_size: 1000,
  pushList: [],
};

const getters = {
  getQuestionnaire: (state) => state.currentQuestionnaire,
  getQuestionnaireTitle: (state) => state.currentQuestionnaire.title,
  getList: (state) => state.list, // 可依照狀態 filter
  getPushList: (state) => state.pushList,
  getListCount: (state) => state.list_count,
  getListPageSize: (state) => state.page_size,
  getChangeInfo: (state) => state.changeInfo,
};

const mutations = {
  TOGGLE_ACTIVE_TAB: (state, activeTab) => {
    state.activeTab = activeTab;
  },
  SET_CURRENT_QUESTIONNAIRE: (state, questionnaire) => {
    state.currentQuestionnaire = questionnaire;
  },
  SET_LIST: (state, list) => {
    state.list = list.data;
    state.list_count = list.total_count;
  },
  SET_NEW_ENDDATE: (state, questionnaire) => {
    const index = state.list.findIndex(
      (item) => item.id === questionnaire.questionnaire_id
    );
    state.list[index].available_end_datetime = questionnaire.extend_datetime;
  },
  SET_PUSH_LIST: (state, list) => {
    state.pushList = list;
  },
  SET_NEW_DELIVER: (state, questionnaire) => {
    const index = state.list.findIndex(
      (item) => item.id === questionnaire.questionnaire_id
    );
    state.list[index].deliver_count = questionnaire.deliver_count;
  },
  SET_CHANGE_INFO(state, status) {
    state.changeInfo = status;
  },
};

const actions = {
  toggleActiveTab({ commit }, activeTab) {
    commit('TOGGLE_ACTIVE_TAB', activeTab);
  },
  async fetchList({ commit }, data) {
    try {
      const input = {
        status: data.status || '1',
        start: data.page ? (data.page * 1 - 1) * state.page_size : 0,
        length: state.page_size,
      };
      const res = await getQuestionnaireList(input);
      commit('SET_LIST', res);
    } catch (error) {
      Message.error(error.message);
    }
  },
  setCurrentQuestionnaire({ commit }, questionnaire) {
    commit('SET_CURRENT_QUESTIONNAIRE', questionnaire);
  },
  async doExtendTime({ commit }, questionnaire) {
    try {
      const res = await extendQuestionnaire(questionnaire);
      commit('SET_NEW_ENDDATE', questionnaire);
      Message.success(res.message);
      return res;
    } catch (error) {
      Message.error(error.message);
    }
  },
  async doGetRetake({ commit }, questionnaireId) {
    try {
      const res = await getRetakeCount(questionnaireId);
      return res;
    } catch (error) {
      Message.error(error.message);
    }
  },
  async doGetPushCount({ commit }, questionnaireId) {
    try {
      const res = await getPushCount(questionnaireId);
      return res;
    } catch (error) {
      Message.error(error.message);
    }
  },
  async doResendPush({ commit }, data) {
    try {
      const res = await resendPush(data);
      Message.success('再次推播申請成功！');
      commit('SET_NEW_DELIVER', { ...data, ...res });
      return res;
    } catch (error) {
      Message.error(error.message);
    }
  },
  async doGetPushList({ commit }, questionnaireId) {
    try {
      const res = await getPushList(questionnaireId);
      commit('SET_PUSH_LIST', res.resend_history);
    } catch (error) {
      Message.error(error.message);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
