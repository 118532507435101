<template>
  <el-form :model="form" :rules="rules" ref="form" style="display: flex">
    <!-- <el-form-item prop="curyear">
      <el-select v-model="form.curyear" placeholder="請選擇" @change="getData">
        <el-option
          v-for="y in years"
          :value="y"
          :key="y"
          :label="y"
        ></el-option>
      </el-select>
      <span style="margin: 0 10px 0 5px">年</span>
    </el-form-item> -->

    <el-form-item prop="curmonth">
      <el-select v-model="form.curmonth" placeholder="請選擇" @change="getData">
        <el-option
          v-for="m in months"
          :value="m"
          :key="m"
          :label="m"
        ></el-option>
      </el-select>
      <span style="margin: 0 5px">月</span>
    </el-form-item>

    <el-form-item prop="curday">
      <el-select v-model="form.curday" placeholder="請選擇" @change="getData">
        <el-option v-for="d in days" :value="d" :key="d" :label="d"></el-option>
      </el-select>
      <span style="margin: 0 10px 0 5px">日</span>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    var validateYear = (rule, value, callback) => {
      if (!value) {
        callback(new Error('請選擇'));
      } else {
        callback();
      }
    };
    var validateMonth = (rule, value, callback) => {
      if (!value) {
        callback(new Error('請選擇'));
      } else {
        callback();
      }
    };
    var validateDay = (rule, value, callback) => {
      if (!value) {
        callback(new Error('請選擇'));
      } else {
        callback();
      }
    };
    return {
      form: {
        //curyear: 1970,
        curyear: new Date().getFullYear(),
        curmonth: new Date().getMonth() + 1,
        curday: new Date().getDate(),
      },
      rules: {
        curyear: [{ validator: validateYear, trigger: 'change' }],
        curmonth: [{ validator: validateMonth, trigger: 'change' }],
        curday: [{ validator: validateDay, trigger: 'blur' }],
      },
      monthDays: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index
      );
    },
    months() {
      return 12;
    },
    days() {
      if (this.form.curmonth === 2 && this.isLeapYear(this.form.curyear)) {
        return 29;
      } else {
        return this.monthDays[this.form.curmonth - 1];
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    isLeapYear(year) {
      return year % 4 === 0 || (year % 100 === 0 && year % 400 === 0);
    },
    getData() {
      this.$emit(
        'handleSelected',
        this.questionData,
        //`${this.form.curyear}/${this.form.curmonth}/${this.form.curday}`,
        `${this.form.curmonth}/${this.form.curday}`,
        this.questionBlock
      );
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__content {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 320px) {
  .el-form {
    flex-wrap: wrap;
  }
}
</style>
