import { getUserData, invoiceSetting, getLoveCodeList } from '@/api/myAccount';
import { MessageBox, Message } from 'element-ui';

export default {
  state: {
    userData: {},
    invoice_init: {
      invoice_type: 'type2',
      org_name: '',
      org_tax: '',
      invoice_code_type: '手機條碼載具',
      invoice_code: '',
      donate: '',
      love_code: '',
      social_welfare_name: '',
    },
  },
  getters: {
    getUserAccount: (state) => state.userData,
    getInitInvoice: (state) => state.invoice_init,
  },
  mutations: {
    //寫入user資料
    GETDATA_SUCCESS(state, data) {
      state.userData = data;
      state.invoice_init.org_name = data.org_name;
      state.invoice_init.org_tax = data.org_tax;
    },
    //編輯發票資料
    EDIT_INVOICE(state, data) {
      state.userData.carrier_type = data.carrier_type;
      state.userData.carrier_number = data.carrier_number;
      state.userData.invoice_heading = data.invoice_heading;
      state.userData.social_welfare_name = data.social_welfare_name;
    },
    //根據response data初始化發票欄位
    INIT_INVOICE(state, data) {
      switch (data.carrier_type) {
        case 0:
          state.invoice_init.invoice_type = 'type1';
          state.invoice_init.org_name = data.invoice_heading;
          state.invoice_init.org_tax = data.carrier_number;
          state.invoice_init.invoice_code = '';
          state.invoice_init.donate = '';
          break;
        case 1:
          state.invoice_init.invoice_type = 'type2';
          state.invoice_init.invoice_code_type = '手機條碼載具';
          state.invoice_init.invoice_code = data.carrier_number;
          state.invoice_init.donate = '';
          break;
        case 2:
          state.invoice_init.invoice_type = 'type2';
          state.invoice_init.invoice_code_type = '自然人憑證載具';
          state.invoice_init.invoice_code = data.carrier_number;
          state.invoice_init.donate = '';
          break;
        case 3:
          state.invoice_init.invoice_type = 'type3';
          state.invoice_init.donate =
            data.carrier_number + '' + data.social_welfare_name;
          state.invoice_init.love_code = data.carrier_number;
          state.invoice_init.social_welfare_name = data.social_welfare_name;
          state.invoice_init.invoice_code = '';
          break;
      }
    },
    //登出清資料
    LOGOUT_SUCCESS(state) {
      state.userData = {};
      state.invoice_init = {
        invoice_type: 'type2',
        org_name: '',
        org_tax: '',
        invoice_code_type: '手機條碼載具',
        invoice_code: '',
        donate: '',
      };
    },
  },
  actions: {
    //取得使用者資料
    async doGetData({ commit }, user_id) {
      try {
        const res = await getUserData(user_id);
        // console.log(res);
        sessionStorage.setItem('userType', res.type_code);
        commit('GETDATA_SUCCESS', res);
        commit('INIT_INVOICE', res);
      } catch (error) {
        Message.error(error.message);
      }
    },
    //編輯發票資訊
    async doEditInvoice({ commit }, setData) {
      try {
        const res = await invoiceSetting(setData);
        commit('EDIT_INVOICE', setData);
        Message.success(res.message);
      } catch (error) {
        MessageBox.alert(error.message, 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        });
      }
    },
    //取得捐贈碼清單
    async doGetLoveCode(context, queryLove) {
      try {
        const res = await getLoveCodeList(queryLove);
        return res;
      } catch (error) {
        Message.error(error.message);
      }
    },
    //登出清資料
    doCleanData({ commit }) {
      commit('LOGOUT_SUCCESS');
    },
  },
};
