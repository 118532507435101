<template>
  <div class="loading-mask">
    <div class="loading">
      <div class="loading-box">
        <i class="fade-item fade-item1"></i>
        <i class="fade-item fade-item2"></i>
        <i class="fade-item fade-item3"></i>
        <i class="fade-item fade-item4"></i>
        <i class="fade-item fade-item5"></i>
        <i class="fade-item fade-item6"></i>
        <i class="fade-item fade-item7"></i>
        <i class="fade-item fade-item8"></i>
        <i class="fade-item fade-item9"></i>
        <i class="fade-item fade-item10"></i>
        <i class="fade-item fade-item11"></i>
        <i class="fade-item fade-item12"></i>
      </div>
      <!-- <span class="text">資料讀取中</span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'loadingCssBox',
};
</script>

<style type="text/scss" lang="scss" scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 40%;
  height: 20%;
  background-color: black;
  border-radius: 12px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  color: #ffffff;
  font-size: 15px;
}

.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  //background: #fff;
  opacity: 0.5;
  z-index: 999;
}

.loading .loading-box {
  position: relative;
  width: 70px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  margin: 0 auto;
}

.loading .loading-box .fade-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  margin: 10px 0px 0px 10px;
}

.loading .loading-box .fade-item:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 4px;
  height: 14px;
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.loading .loading-box .fade-item2 {
  transform: rotate(30deg);
}

.loading .loading-box .fade-item3 {
  transform: rotate(60deg);
}

.loading .loading-box .fade-item4 {
  transform: rotate(90deg);
}

.loading .loading-box .fade-item5 {
  transform: rotate(120deg);
}

.loading .loading-box .fade-item6 {
  transform: rotate(150deg);
}

.loading .loading-box .fade-item7 {
  transform: rotate(180deg);
}

.loading .loading-box .fade-item8 {
  transform: rotate(210deg);
}

.loading .loading-box .fade-item9 {
  transform: rotate(240deg);
}

.loading .loading-box .fade-item10 {
  transform: rotate(270deg);
}

.loading .loading-box .fade-item11 {
  transform: rotate(300deg);
}

.loading .loading-box .fade-item12 {
  transform: rotate(330deg);
}

.loading .loading-box .fade-item:before {
  animation: fadeDelay 1.1s infinite ease-in-out both;
}

.loading .loading-box .fade-item2:before {
  animation-delay: -1s;
}

.loading .loading-box .fade-item3:before {
  animation-delay: -9s;
}

.loading .loading-box .fade-item4:before {
  animation-delay: -0.8s;
}

.loading .loading-box .fade-item5:before {
  animation-delay: -0.7s;
}

.loading .loading-box .fade-item6:before {
  animation-delay: -0.7s;
}

.loading .loading-box .fade-item7:before {
  animation-delay: -0.6s;
}

.loading .loading-box .fade-item8:before {
  animation-delay: -0.5s;
}

.loading .loading-box .fade-item9:before {
  animation-delay: -0.4s;
}

.loading .loading-box .fade-item10:before {
  animation-delay: -0.3s;
}

.loading .loading-box .fade-item11:before {
  animation-delay: -0.2s;
}

.loading .loading-box .fade-item12:before {
  animation-delay: -0.1s;
}

@keyframes fadeDelay {
  0%,
  39%,
  100% {
    opacity: 0.2;
  }
  40% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeDelay {
  0%,
  39%,
  100% {
    opacity: 0.2;
  }
  40% {
    opacity: 1;
  }
}
</style>
