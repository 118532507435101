<template>
  <el-radio-group v-model="value" style="display: block" @change="$emit('handleSelected',questionData,value.option_id,questionBlock,value)">
    <el-radio
      v-for="(options, index) in questionData.option_array"
      :key="index"
      :label="options"
      :value="options.option_id"
      style="display: block; margin: 10px 0"
      border
    >{{options.option_title}}</el-radio>
  </el-radio-group>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      value: '',
    };
  },
};
</script>