import { toPairs } from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Target-Q問卷-登入',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: {
      title: 'Target-Q問卷-註冊',
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('../views/Verify.vue'),
    meta: {
      title: '驗證 ...',
    },
  },
  {
    path: '/',
    redirect: '/questionnaire-list',
  },
  {
    path: '/target-qa',
    name: 'target-qa',
    component: () => import('../views/TargetQa.vue'),
    meta: {
      title: 'Target-Q 問卷QA',
    },
  },
  {
    path: '',
    name: '問卷',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/create-questionnaire',
        name: '新增問卷',
        component: () =>
          import('../views/questionnaire/CreateQuestionnaire.vue'),
        meta: {
          title: '新增問卷',
        },
      },
      {
        path: '/questionnaire-list',
        name: '問卷列表',
        component: () => import('../views/questionnaire/QuestionnaireList.vue'),
        meta: {
          title: 'Target-Q 問卷列表',
        },
      },
      {
        path: '/qa',
        name: '常見QA',
        component: () => import('../views/Qa.vue'),
        meta: {
          title: 'QA',
        },
      },
    ],
  },

  {
    path: '/app-list',
    name: '探索雲端任務列表',
    meta: {
      title: '任務列表',
      layout: 'app',
      keepAlive: true,
    },
    component: () => import('../views/appLayout/AppList.vue'),
  },
  {
    path: '/app-intro',
    name: 'APP問卷介紹頁',
    meta: {
      title: '雲端問卷',
      layout: 'app',
    },
    component: () => import('../views/appLayout/AppIntro.vue'),
  },
  {
    path: '/app',
    name: '雲端發票APP問卷回答頁',
    meta: {
      title: '雲端問卷',
      layout: 'app',
    },
    component: () => import('../views/appLayout/FormalContent.vue'),
  },
  {
    path: '/app-error',
    name: '雲端發票APPh錯誤頁',
    meta: {
      title: '',
      layout: 'app',
    },
    component: () => import('../views/appLayout/ErrorPage.vue'),
  },
  {
    path: '/account',
    name: '我的帳戶',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/user-profile',
        name: '',
        component: () => import('../views/account/userProfile.vue'),
        meta: {
          title: '我的帳戶',
        },
      },
      {
        path: '/trade-detail',
        name: '交易明細',
        component: () => import('../views/account/tradeDetail.vue'),
        meta: {
          title: '交易明細',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_URL_PATH,
  routes,
  //keep-alive(APP C端)
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.layout === 'app' && to.meta.keepAlive) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    }
  },
});

const DEFAULT_TITLE = 'Target-Q 問卷平台';

router.beforeEach((to, from, next) => {
  // 不同頁籤共享登入
  // const checkSession = JSON.parse(sessionStorage.getItem('session'));
  // if (checkSession) {
  //   if (checkSession.user.session) {
  //     sessionStorage.setItem('session', checkSession.user.session);
  //   }
  // } else {
  //   sessionStorage.clear();
  // }

  if (from.path === '/app' && to.path !== '/app') {
    store.dispatch('clearStorage');
    console.log('clearStorage');
  }

  if (to.path === '/app-intro' && to.query.hash_user_id) {
    store.dispatch('setAppToken', {
      ...{ hash_user_id: to.query.hash_user_id, enter_page: 'app-intro' },
    });
  }

  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  const isLogin = sessionStorage.getItem('session');

  if (to.meta.layout === 'app') {
    store.dispatch('sentApp', {
      action: 'configureAppWebPage',
      payload: { title: to.meta.title },
    });
    store.dispatch('getDeviceInformation');
    //檢查Ｃ端ＡＰＰ版本是否支援
    if (store.getters.beUsablePoint || to.path === '/app-error') {
      next();
    } else {
      next({ path: '/app-error', replace: true });
    }
  } else {
    if (isLogin) {
      next();
      if (to.path == '/login') {
        next('/');
      }
    } else {
      if (
        to.path !== '/login' &&
        to.path !== '/register' &&
        to.path !== '/verify' &&
        to.path !== '/target-qa'
      )
        next('/login');
      else next();
    }
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
