import request from '@/utils/request';
const BASE_URL = '/targetQ/api/questionnaire';

// 取得app入口問卷列表
export function getList(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/listAvailableQuestionnaire`,
    data: data,
  });
}

// 驗證使用者的Token
export function getUserId(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/doVerifyIdToken`,
    data,
  });
}

// 開始回答問卷
export function startFillQuestionnaire(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/startFillInQuestionnaire`,
    data,
  });
}

// 取得開始的問題
export function getStartQuestion(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/getStartQuestion`,
    data,
  });
}

// 回答問卷答案
export function saveAnswer(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/saveAnswer`,
    data,
  });
}

// 離開問卷
export function doExit(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/exitQuestionnaire`,
    data,
  });
}

// 取得問卷結束畫面訊息
export function getFinishInfo(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/getFinishPageInfo`,
    data,
  });
}

// 查詢問卷的詳細資訊
export function getQuestionnaireDetail(data) {
  return request({
    method: 'post',
    url: `/targetQ/api/respondents/getQuestionnaireDetail`,
    data,
  });
}