<template>
  <div>
    <el-rate
      v-model="value"
      @change="$emit('handleSelected', questionData, value, questionBlock)"
    ></el-rate>
  </div>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      value: null,
    };
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-icon-star-off {
  &::before {
    font-size: 32px;
  }
}

/deep/ .el-icon-star-on {
  transform: scale(1.1);
  &::before {
    font-size: 32px;
  }
}
/deep/ .el-rate {
  outline: none;
  height: 32px;
}
</style>
