<template>
  <div>
    <div
      v-for="option in questionData.option_array"
      :key="option.option_id"
      class="question-image"
      @click="handleOptionSelected(option)"
    >
      <div>
        <img v-lazy="option.option_image" class="lazy-img" />
      </div>
      <div class="question-image__text">
        <el-radio
          v-model="value"
          :label="option"
          :value="option.option_id"
          @change="handleOptionSelected"
        >
          {{ option.option_title }}
        </el-radio>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    handleOptionSelected(option) {
      if (option) {
        this.value = option;
      }
      this.$emit(
        'handleSelected',
        this.questionData,
        this.value.option_id,
        this.questionBlock,
        this.value
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.question-image {
  border: 1px solid #979797;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  &__text {
    text-align: center;
  }
}
img {
  width: 100%;
}
</style>
