<template>
  <el-form :model="ruleForm" :rules="rules" ref="email" @submit.native.prevent>
    <el-form-item prop="email">
      <el-input
        type="text"
        placeholder="請輸入 Email"
        v-model="ruleForm.email"
        @change="handleAns"
        @blur="handleAns"
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        if (this.questionData.is_required) {
          callback(new Error('請輸入Email'));
        } else {
          callback();
        }
      } else {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = re.test(String(value).toLowerCase());
        isValid ? callback() : callback(new Error('格式錯誤'));
      }
    };
    return {
      ruleForm: {
        email: '',
      },
      rules: {
        email: [{ validator: validateEmail, trigger: 'blur' }],
      },
    };
  },
  methods: {
    handleAns() {
      this.$refs['email'].validate((valid) => {
        if (valid) {
          this.$emit(
            'handleSelected',
            this.questionData,
            this.ruleForm.email,
            this.questionBlock,
            null,
            true
          );
        } else {
          this.$emit(
            'handleSelected',
            this.questionData,
            this.ruleForm.email,
            this.questionBlock,
            null,
            false
          );
        }
      });
    },
  },
};
</script>
