<template>
  <div>
    <el-input
      type="textarea"
      :rows="5"
      placeholder="請輸入文字"
      v-model="textarea"
      @blur="handleAns"
      :class="{ 'input-error': error }"
    ></el-input>
    <div style="height: 17px">
      <span style="color: #f56c6c; font-size: 12px" v-show="error">
        請輸入中文或英文字元填答
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      textarea: '',
      error: false,
    };
  },
  methods: {
    handleAns() {
      if (this.textarea === '') {
        if (this.questionData.is_required) {
          let rule = /[\u4e00-\u9fa5a-zA-Z0-9]/g;
          if (rule.test(this.textarea)) {
            this.error = false;
          } else {
            this.error = true;
          }
        } else {
          this.error = false;
        }
      } else {
        let rule = /[\u4e00-\u9fa5a-zA-Z0-9]/g;
        if (rule.test(this.textarea)) {
          this.error = false;
        } else {
          this.error = true;
        }
      }
      if (this.error) {
        this.$emit(
          'handleSelected',
          this.questionData,
          this.textarea,
          this.questionBlock,
          null,
          false
        );
      } else {
        this.$emit(
          'handleSelected',
          this.questionData,
          this.textarea,
          this.questionBlock,
          null,
          true
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-error {
  /deep/ .el-textarea__inner {
    border-color: #f56c6c;
  }
}

/deep/ .el-textarea__inner {
  padding: 15px;
}
</style>
