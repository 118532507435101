<template>
  <el-radio-group v-model="value" style="display: block">
    <el-radio
      v-for="(options, index) in questionData.select"
      :key="index"
      :label="`${options.label}，${options.text}`"
      :value="options.label"
      style="display: block; margin: 10px 0"
      border
    ></el-radio>
  </el-radio-group>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      value: '',
    };
  },
};
</script>
