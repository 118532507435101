import request from '@/utils/request';
const baseURL = '/targetQ/user/';

//會員登入
export function userLogin(data) {
  return request({
    method: 'post',
    url: `${baseURL}login`,
    data,
  });
}

//會員註冊
export function userRegister(data) {
  return request({
    method: 'post',
    url: `${baseURL}register`,
    data,
  });
}

//驗證使用者信箱
export function emailVerify(data) {
  return request({
    method: 'post',
    url: `${baseURL}emailVerify`,
    data: {
      token: data,
    },
  });
}

//重發驗證碼
export function resendEmailVerify(data) {
  return request({
    method: 'post',
    url: `${baseURL}resendEmailVerify`,
    data: {
      account: data,
    },
  });
}

//會員登出
export function userLogout() {
  return request({
    method: 'post',
    url: `${baseURL}logout`,
  });
}
