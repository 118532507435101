import { userPointHistory, getUserPoint } from '@/api/myPoint';
import { Message } from 'element-ui';

export default {
  state: {
    pointData: {},
    pointHistory: [],
    pointHistory_count: 0,
    historyPage_size: 1000,
  },
  getters: {
    getUserPointData: (state) => state.pointData,
    getPointHistory: (state) => state.pointHistory,
    getHistoryCount: (state) => state.pointHistory_count,
    getHistoryPageSize: (state) => state.historyPage_size,
  },
  mutations: {
    //寫入目前點數
    GETPPOINT_SUCCESS(state, data) {
      state.pointData = data;
    },
    //寫入點數歷程
    SET_POINT_HISTORY(state, data) {
      state.pointHistory = data.history_list;
      state.pointHistory_count = data.total_count;
    },
  },
  actions: {
    //取得使用者目前點數
    async doGetPoint({ commit }) {
      try {
        const res = await getUserPoint();
        // console.log(res);
        commit('GETPPOINT_SUCCESS', res.point_info);
      } catch (error) {
        Message.error(error.message);
      }
    },
    //取得點數歷程
    async doGetPointHistory({ state, commit }, page) {
      try {
        const input = {
          start: (page * 1 - 1) * state.historyPage_size,
          length: state.historyPage_size,
        };
        const res = await userPointHistory(input);
        commit('SET_POINT_HISTORY', res);
      } catch (error) {
        Message.error(error.message);
      }
    },
  },
};
