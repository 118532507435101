<template>
  <div id="app">
    <keep-alive :include="keepAliveList">
      <router-view />
    </keep-alive>
    <p
      v-if="$route.name === 'login' || $route.name === 'register'"
      class="version"
    >
      Version: {{ $store.getters.version }}
    </p>
    <loading-mask v-if="loading && $route.meta.layout === 'app'"></loading-mask>
  </div>
</template>
<script>
import LoadingMask from './components/common/LoadingMask.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'app',
  components: {
    LoadingMask,
  },
  data() {
    return {
      keepAliveList: ['AppList'],
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  watch: {
    $route() {
      console.log(this.$route.meta.layout === 'app');

      if (this.$route.meta.layout === 'app') {
        document.documentElement.style.background = '#fff';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
