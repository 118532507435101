<template>
  <div class="videoWrap">
    <iframe
      width="100%"
      :src="`https://www.youtube.com/embed/${getVideoId}`"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      style="border: 0px"
    ></iframe>
    <el-radio-group style="display: block" v-model="value" @change="$emit('handleSelected',questionData,value.option_id,questionBlock, value)">
      <el-radio
        v-for="(options, index) in questionData.option_array"
        :key="index"
        :label="options"
        :value="options.option_id"
        style="display: block; margin: 10px 0"
        border
      >{{options.option_title}}</el-radio>
    </el-radio-group>
  </div>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    getVideoId() {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = this.questionData.video_link.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
};
</script>
