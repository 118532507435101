import request from '@/utils/request';
const baseURL = '/questionnaire/backend/questionGroup';

// 新增&編輯題組
export function doSaveGroup(data) {
  return request({
    method: 'post',
    url: `${baseURL}/doSave`,
    data,
  });
}

// 刪除題組
export function doDeleteGroup(group_id) {
  return request({
    method: 'post',
    url: `/targetQ/group/doDelete`,
    data: {
      group_id,
    },
  });
}

// 複製題組
export function doCloneGroup(data) {
  return request({
    method: 'post',
    url: `/targetQ/group/doClone`,
    data,
  });
}
