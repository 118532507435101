import { doExit } from '@/api/targetQ';
import router from '../../router';

const getDefaultState = () => {
  return {
    current_ques_index: null,
  };
};

const state = getDefaultState();

const getters = {
  getCurQuesIndex: (state) => state.current_ques_index,
};

const mutations = {
  CLEAR_STORAGE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_CURRENT_QUESTION_INDEX: (state, index) => {
    state.current_ques_index = index;
  },
};

const actions = {
  clearStorage({ commit }) {
    commit('CLEAR_STORAGE');
  },
  setCurrentQuestionIndex({ commit }, index) {
    commit('SET_CURRENT_QUESTION_INDEX', index);
  },
  async appDoExit({ commit }, userToken) {
    await doExit(userToken);
    router.replace({
      path: '/app-list',
      query: {
        access_token: router.currentRoute.query.access_token,
        type: 'timeout',
      },
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
