import Vue from 'vue';

Vue.directive('price-format', {
  bind: (el, binding) => {
    let valueAvailable = !!binding.value;
    if (valueAvailable || binding.value === 0) {
      el.innerHTML = binding.value
        .toString()
        .replace(
          /^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
          function (all, pre, groupOf3Digital) {
            return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
          }
        );
    }
  },
  update: (el, binding) => {
    let valueAvailable = !!binding.value;
    if (valueAvailable || binding.value === 0) {
      el.innerHTML = binding.value
        .toString()
        .replace(
          /^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/,
          function (all, pre, groupOf3Digital) {
            return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
          }
        );
    }
  },
});
