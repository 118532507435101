const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  formColumnSpan: (state) => state.app.formColumnSpan,
  loading: (state) => state.app.loading,
  activeTab: (state) => state.questionnaire.activeTab,
  version: (state) => state.app.version,
  environment: (state) => state.app.environment,
};
export default getters;
