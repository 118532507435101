<template>
  <!-- 複選無法跳題 -->
  <div>
    <el-checkbox-group
      class="checkbox-group"
      v-model="checkList"
      :max="questionData.max_select !== 0 ? questionData.max_select : 99"
      @change="handleAns"
    >
      <el-checkbox
        v-for="(option, index) in questionData.option_array"
        :key="index"
        :label="option.option_id"
        :value="option.option_id"
        style="display: block; margin: 10px 0"
      >
        {{ option.option_title }}
      </el-checkbox>
    </el-checkbox-group>
    <div style="height: 17px">
      <span style="color: #f56c6c; font-size: 12px" v-show="error">
        至少選擇一選項
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      checkList: [],
      error: false,
    };
  },
  computed: {
    checked() {
      return this.checkList.toString();
    },
  },
  methods: {
    handleAns() {
      if (this.checkList.length === 0) {
        if (this.questionData.is_required) {
          this.error = true;
        } else {
          this.error = false;
        }
      } else {
        this.error = false;
      }

      if (this.error) {
        this.$emit(
          'handleSelected',
          this.questionData,
          this.checked,
          this.questionBlock,
          null,
          false
        );
      } else {
        this.$emit(
          'handleSelected',
          this.questionData,
          this.checked,
          this.questionBlock,
          null,
          true
        );
      }
    },
  },
};
</script>
<style type="text/scss" lang="scss" scoped>
.checkbox-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/deep/ .el-checkbox {
  height: 131px;
  padding: 10px 18px;
  display: flex !important;
  align-items: center;
  writing-mode: vertical-lr;
  direction: ltr;
  overflow: hidden;
}

/deep/ .el-checkbox__label {
  padding-left: 0;
  margin-top: 11px;
}

/deep/ .el-checkbox__inner {
  width: 24px;
  height: 24px;

  &::after {
    height: 10px;
    top: 3px;
    left: 8px;
    width: 5px;
  }
}
</style>
