import { userLogout } from '@/api/userAction';
import { MessageBox } from 'element-ui';
import Router from '../../router';
import store from '../../store';

export default {
  state: {
    login: {},
    register: {},
    app: {
      hash_user_id: '',
      c_session_id: '',
      enter_page: '',
    },
  },

  getters: {
    getLoginState: (state) => state.login,
    getRegisterState: (state) => state.register,
    getAppUserToken: (state) => state.app,
  },

  mutations: {
    LOGIN_SUCCESS(state, loginData) {
      state.login = loginData;
    },
    REGISTER_BASIC(state, registerData) {
      state.register = registerData;
    },
    LOGOUT_SUCCESS(state) {
      state.login = {};
      state.register = {};
    },
    //寫入APP使用者資料
    SET_APP_TOKEN(state, token) {
      state.app = token;
    },
  },

  actions: {
    doLogin({ commit }, loginData) {
      //session/user_id存到sessionStorage中
      sessionStorage.setItem('session', loginData.session);
      sessionStorage.setItem('user_id', loginData.user_id);
      //更新vuex
      commit('LOGIN_SUCCESS', loginData);
    },
    setRegisterStep1({ commit }, registerData) {
      commit('REGISTER_BASIC', registerData);
    },
    async doLogout({ commit }) {
      await userLogout();
      commit('LOGOUT_SUCCESS');
      store.dispatch('doCleanData');
      sessionStorage.clear();
      localStorage.clear();
      Router.push('/login');
    },
    //寫入APP使用者資料
    setAppToken({ commit }, token) {
      commit('SET_APP_TOKEN', token);
    },
  },
};
