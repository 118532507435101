import address from './address';
import fullAddressList from './fullAddressList';

export function getAddressList() {
  return new Promise((resolve) => {
    resolve(address);
  });
}

export function getFullAddressList() {
  return new Promise((resolve) => {
    resolve(fullAddressList);
  });
}
