import request from '@/utils/request';

//取得未開始/刊登中/已結束問卷列表
export function getQuestionnaireList(data) {
  return request({
    method: 'post',
    url: '/targetQ/questionnaire/getQuestionnaireList',
    data,
  });
}

//新增&編輯問卷
export function saveQuestionnaire(data) {
  return request({
    method: 'post',
    url: '/targetQ/questionnaire/doSave',
    data,
  });
}

//上傳圖片檔案
export function doUploadImage(data) {
  return request({
    method: 'post',
    url: '/targetQ/file/upload',
    data,
  });
}

//問卷送出申請
export function submitQuestionnaire(data) {
  return request({
    method: 'post',
    url: '/targetQ/questionnaire/doSubmitQuestionnaireForReview',
    data,
  });
}

//取得問卷資訊不含題目
export function getDetail(id) {
  return request({
    method: 'get',
    url: `/questionnaire/backend/question/getDetail?id=${id}`,
  });
}

// 刪除問卷
export function deleteQuestionnair(id) {
  return request({
    method: 'post',
    url: `/targetQ/questionnaire/doDeleteQuestionnaire`,
    data: {
      id,
    },
  });
}

// 延長問卷
export function extendQuestionnaire(data) {
  return request({
    method: 'post',
    url: `/targetQ/questionnaire/doExtendQuestionnaire`,
    data,
  });
}

//強制結束問卷
export function forceStopQuestionnaire(questionnaire_id) {
  return request({
    method: 'post',
    url: `/targetQ/questionnaire/doForceStopQuestionnaire`,
    data: {
      questionnaire_id,
    },
  });
}

//複製問卷
export function cloneQuestionnaire(questionnaire_id) {
  return request({
    method: 'post',
    url: `/targetQ/questionnaire/doClone`,
    data: {
      questionnaire_id,
    },
  });
}

//取得單個問卷最新回收數
export function getRetakeCount(questionnaire_id) {
  return request({
    method: 'post',
    url: '/targetQ/questionnaire/getRetakeCount',
    data: {
      questionnaire_id,
    },
  });
}

//取得單個問卷最新推播人數
export function getPushCount(questionnaire_id) {
  return request({
    method: 'post',
    url: '/targetQ/questionnaire/canPushCount',
    data: {
      questionnaire_id,
    },
  });
}

//發送推播送出申請
export function resendPush(data) {
  return request({
    method: 'post',
    url: '/targetQ/questionnaire/resendPush',
    data,
  });
}

//取得單個問卷再次推播清單
export function getPushList(questionnaire_id) {
  return request({
    method: 'post',
    url: '/targetQ/questionnaire/pushList',
    data: {
      questionnaire_id,
    },
  });
}

//報表下載
export function getReport(data) {
  return request({
    timeout: 200000,
    method: 'post',
    url: '/targetQ/questionnaire/getReport',
    data: { ...data, format: 'CSV' },
    responseType: 'blob',
  });
}
