export default {
  state: {
    deviceVersion: '',
    platform: '',
    system: '',
    webInApp: Boolean,
    beUsablePoint: false,
  },
  mutations: {
    SENT_APP(state, sent) {
      const data = { action: sent.action, payload: sent.payload };
      try {
        let isIOS =
          (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' &&
              navigator.maxTouchPoints > 1)) &&
          !window.MSStream;
        if (navigator.userAgent.match(/Android/i)) {
          // eslint-disable-next-line no-undef
          app.android(JSON.stringify(data));
        } else if (isIOS) {
          window.webkit.messageHandlers.ios.postMessage(data);
        }
      } catch (e) {
        //alert('請下載雲端發票app！');
      }
    },
    GET_DEVICE_INFORMATION(state) {
      state.deviceVersion = navigator.userAgent.split('/')[1].split(' ')[0];
      const ua = navigator.userAgent.toLowerCase();
      const testUa = (regexp) => regexp.test(ua);

      // 系統
      if (testUa(/windows|win32|win64|wow32|wow64/gi)) {
        state.system = 'windows'; // window系統
      } else if (testUa(/macintosh|macintel/gi)) {
        state.system = 'macos'; // macos系統
      } else if (testUa(/x11/gi)) {
        state.system = 'linux'; // linux系統
      } else if (testUa(/android|adr/gi)) {
        state.system = 'Android'; // android系統
      } else if (testUa(/ios|iphone|ipad|ipod|iwatch/gi)) {
        state.system = 'iOS'; // ios系統
      }
      //裝置平台
      let isMobile =
        'ontouchstart' in document.documentElement ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;

      if (
        (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
          navigator.userAgent
        ) ||
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.platform
          )) &&
        isMobile
      ) {
        state.platform = 'mobile';
      } else {
        state.platform = 'desktop';
      }

      //webview
      if (
        testUa(
          /(WebView|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv|.0.0.0))/gi
        )
      ) {
        state.webInApp = true;
      } else {
        state.webInApp = false;
      }
    },
    CHECK_IS_HAVE_POINT_FUNCTION(state) {
      if (state.platform === 'mobile') {
        const iOS_point_version =
          compare(state.deviceVersion, '3.4.7') && state.system === 'iOS';
        const Android_point_version =
          compare(state.deviceVersion, '3.4.9') && state.system === 'Android';
        if (iOS_point_version || Android_point_version) {
          state.beUsablePoint = true;
        }
      } else {
        state.beUsablePoint = false;
      }
    },
  },
  actions: {
    sentApp({ commit }, data) {
      commit('SENT_APP', data);
    },
    getDeviceInformation({ commit }) {
      commit('GET_DEVICE_INFORMATION');
      commit('CHECK_IS_HAVE_POINT_FUNCTION');
    },
  },
  getters: {
    deviceVersion: (state) => state.deviceVersion,
    platform: (state) => state.platform,
    system: (state) => state.system,
    webInApp: (state) => state.webInApp,
    beUsablePoint: (state) => state.beUsablePoint,
  },
};

function compare(curV, reqV) {
  if (curV && reqV) {
    //目前版號：curV；比較版號：reqV
    const arr1 = curV.split('.'),
      arr2 = reqV.split('.');
    let minLength = Math.min(arr1.length, arr2.length),
      position = 0,
      diff = 0;
    while (
      position < minLength &&
      (diff = parseInt(arr1[position]) - parseInt(arr2[position])) === 0
    ) {
      position++;
    }
    diff = diff !== 0 ? diff : arr1.length - arr2.length;
    return diff >= 0;
  } else {
    return false;
  }
}
