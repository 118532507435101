<template>
  <el-radio-group
    class="radio-group"
    v-model="value"
    style="display: block"
    @change="
      $emit(
        'handleSelected',
        questionData,
        value.option_id,
        questionBlock,
        value
      )
    "
  >
    <el-radio
      v-for="(options, index) in questionData.option_array"
      :key="index"
      :label="options"
      :value="options.option_id"
      style="display: block; margin: 10px 0"
    >
      {{ options.option_title }}
    </el-radio>
  </el-radio-group>
</template>
<script>
export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    return {
      value: '',
    };
  },
};
</script>
<style type="text/scss" lang="scss" scoped>
.radio-group {
  display: flex !important;
  flex-direction: row;
  justify-content: space-around;
}

/deep/ .el-radio {
  height: 131px;
  padding: 0 18px;
  display: flex !important;
  align-items: center;
  writing-mode: vertical-lr;
  direction: ltr;
  overflow: hidden;
}

/deep/ .el-radio__label {
  padding-left: 0;
  margin-top: 11px;
}

/deep/ .el-radio__inner {
  width: 24px;
  height: 24px;

  &::after {
    width: 8px;
    height: 8px;
  }
}

.option-vertical {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.option {
  display: inline-block;
  writing-mode: vertical-lr;
  direction: ltr;
}

.radioButton {
  display: flex;
  flex-direction: column;
  writing-mode: vertical-lr;
  margin-bottom: 0;
  height: 140px;

  span {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
</style>
