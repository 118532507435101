import request from '@/utils/request';
const baseURL = '/targetQ/user/point/';

//取得會員目前點數
export function getUserPoint() {
  return request({
    method: 'post',
    url: `${baseURL}query`,
  });
}

//取得點數歷程
export function userPointHistory(data) {
  return request({
    method: 'post',
    url: `${baseURL}history`,
    data,
  });
}
