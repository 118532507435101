import request from '@/utils/request';
const baseURL = '/targetQ/';

//取得會員Data
export function getUserData(data) {
  return request({
    method: 'post',
    url: `${baseURL}user/getUserData`,
    data: {
      user_id: data,
    },
  });
}

//編輯發票資訊
export function invoiceSetting(data) {
  return request({
    method: 'post',
    url: `${baseURL}user/doUpdateUserInvoiceSetting`,
    data,
  });
}

//取得捐贈碼清單
export function getLoveCodeList(data) {
  return request({
    method: 'post',
    url: `${baseURL}other/getLoveCodeList`,
    data,
  });
}
