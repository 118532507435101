import request from '@/utils/request';
const baseURL = '/questionnaire/backend';

// 新增&編輯題目
export function doSaveSingleQuestion(data) {
  return request({
    method: 'post',
    url: `${baseURL}/questionBank/doSave`,
    data,
  });
}

// 取得題目詳細內容(選項)
export function getQuestionDetail(questionId) {
  return request({
    method: 'get',
    url: `${baseURL}/questionBank/getDetail?id=${questionId}`,
  });
}

// 刪除題目
export function doDeleteQuestion(question_id) {
  return request({
    method: 'post',
    url: `/targetQ/question/doDelete`,
    data: {
      question_id,
    },
  });
}

// 複製題目
export function doCloneQuestion(data) {
  return request({
    method: 'post',
    url: `/targetQ/question/doClone`,
    data,
  });
}

/**
 * 以下是歸屬於「問卷」的 API
 */

// 儲存問卷排序
export function doSaveAllQuestions(data) {
  return request({
    method: 'post',
    url: `${baseURL}/question/doSaveQuestion`,
    data,
  });
}

// 取的問卷題目列表-不含選項
export function getQuestionList(questionnaireId) {
  return request({
    method: 'get',
    url: `${baseURL}/question/getQuestionList?id=${questionnaireId}`,
  });
}

// 新版-取得問卷題目列表含選項
export function getAllQuestionList(questionnaireId) {
  return request({
    method: 'post',
    url: `/targetQ/questionnaire/getQuestionList`,
    data: {
      id: questionnaireId,
    },
  });
}
