<template>
  <div class="bar-wrap">
    <el-progress
      v-if="!isNaN(cPercent)"
      :stroke-width="10"
      :percentage="cPercent"
      color="#71d3ff"
      :show-text="false"
    ></el-progress>
    <div class="word">{{ curEx }}/{{ totalCount }}</div>
  </div>
</template>

<script>
export default {
  name: 'QuestionProgressBar',
  props: {
    totalCount: { type: Number, default: 0 },
    curEx: { type: Number },
  },
  computed: {
    cPercent() {
      let percent = (this.curEx / this.totalCount) * 100;
      return percent > 100 ? 100 : Math.floor(percent);
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-wrap {
  padding: 20px;
}
.word {
  padding: 20px;
  text-align: center;
  color: #b0afb0;
  font-size: 13px;
}
</style>
