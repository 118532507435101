import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/main.scss';
import '@/icons';
import './utils/priceFormat';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-TW';

import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import './utils/addDays';
// For mobile smoothscroll
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
import { ellipsisDirective, ellipsisComponent } from '@hyjiacan/vue-ellipsis';
// For directive usage
Vue.directive(ellipsisDirective.name, ellipsisDirective);
// For component usage
Vue.component(ellipsisComponent.name, ellipsisComponent);
//img lazyload
import VueLazyLoad from 'vue-lazyload';
Vue.use(VueLazyLoad);

const requireComponent = require.context(
  '@/components/realFrame/question',
  false,
  /Question[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
