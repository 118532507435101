import axios from 'axios';
import { Message } from 'element-ui';
import store from '../store';
import router from '../router';

// create an axios instance
const service = axios.create({
  baseURL: process.env.baseUrl, //process.env.publicPath
  //timeout: 5000, // request timeout
  headers: {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache,no-store',
  },
});

service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    store.commit('SHOW_LOADING');
    if (store.getters.session || sessionStorage.getItem('session')) {
      config.headers['session'] =
        store.getters.session || sessionStorage.getItem('session');
    }
    return config;
  },
  (error) => {
    store.commit('HIDE_LOADING');
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    store.commit('HIDE_LOADING');
    if (response.data.error == null) {
      return response.data;
    } else {
      if (response.data.error.code === '10001') {
        store.dispatch('doLogout').then(() => {
          Message.error('驗證失敗！請重新登入！');
        });
      } else if (response.data.error.code === '10002') {
        console.log(response.data.error);
      } else {
        if (router.currentRoute.meta.layout === 'app') {
          alert(response.data.error.message);
          //已經超過填寫時間
          if (response.data.error.code === '60107') {
            store.dispatch('appDoExit', store.getters.getAppUserToken);
          } else {
            //問券介紹頁進入，error關閉webview
            if (store.getters.getAppUserToken?.enter_page === 'app-intro') {
              store.dispatch('sentApp', {
                action: 'dismissBrowser',
              });
            } else {
              //問券入口進入，error 返回
              router.back();
            }
          }
          return response.data;
        } else {
          throw response.data.error;
        }
      }
    }
  },
  (error) => {
    store.commit('HIDE_LOADING');
    if (error.code === 'ECONNABORTED') {
      Message.error('Request Timeout');
    }

    if (error.statusCode === 500) {
      Message.error('Server Error');
    }
    return Promise.reject(error);
  }
);

export default service;
