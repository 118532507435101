<template>
  <el-form :model="form" :rules="rules" ref="form">
    <div class="option">
      <el-row :gutter="24" style="margin-bottom: 20px">
        <el-col :span="11">
          <el-form-item prop="city">
            <el-select
              v-model="form.city"
              placeholder="請選擇縣市"
              @blur="getData"
              @focus="clearValidate"
            >
              <el-option
                v-for="(city, index) in addressList"
                :value="city.city_name"
                :label="city.city_name"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="area">
            <el-select
              v-model="form.area"
              placeholder="請選擇區域"
              @blur="getData"
              @focus="clearValidate"
            >
              <el-option
                v-for="(area, index) in areaList"
                :value="area.area_name"
                :key="index"
                :label="area.area_name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="address">
        <el-input
          placeholder="請輸入地址"
          v-model.trim="form.address"
          width="100%"
          @blur="getData"
          @input="clearValidate"
        ></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>
import { getFullAddressList } from '@/utils/data';

export default {
  props: {
    questionData: { type: Object },
    questionBlock: { type: Object },
  },
  data() {
    var validateCity = (rule, value, callback) => {
      if (!value) {
        callback(new Error('請選擇'));
      } else {
        callback();
      }
    };
    var validateArea = (rule, value, callback) => {
      if (!value) {
        callback(new Error('請選擇'));
      } else {
        callback();
      }
    };
    var validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('請輸入'));
      } else {
        callback();
      }
    };
    return {
      addressList: [],
      form: {
        city: '',
        area: '',
        address: '',
      },
      rules: {
        city: [{ validator: validateCity, trigger: 'change' }],
        area: [{ validator: validateArea, trigger: 'change' }],
        address: [{ validator: validateAddress, trigger: 'blur' }],
      },
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    areaList() {
      const array = this.addressList.filter(
        (i) => i.city_name === this.form.city
      )[0];

      return array?.area_list;
    },
  },
  methods: {
    getList() {
      getFullAddressList().then((res) => {
        this.addressList = res;
      });
    },
    getData() {
      this.$refs['form'].validate((valid) => {
        if (valid || !this.questionData.is_required) {
          this.$emit(
            'handleSelected',
            this.questionData,
            `${this.form.city}${this.form.area}${this.form.address}`,
            this.questionBlock,
            null,
            true
          );
        } else {
          this.$emit(
            'handleSelected',
            this.questionData,
            `${this.form.city}${this.form.area}${this.form.address}`,
            this.questionBlock,
            null,

            false
          );
        }
      });
    },
    clearValidate() {
      this.$refs['form'].clearValidate();
    },
  },
};
</script>
