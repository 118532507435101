/**
 * 新增/編輯問題或更動排序時，都還不會直接寫入到問卷內，必須要在最後使用者按下儲存鍵才一次寫入。
 */

import {
  doSaveSingleQuestion,
  doSaveAllQuestions,
  getQuestionList,
  getAllQuestionList,
  doDeleteQuestion,
  doCloneQuestion,
} from '@/api/questionBank';
import { doSaveGroup, doDeleteGroup, doCloneGroup } from '@/api/questionGroup';
import { Message } from 'element-ui';

export default {
  state: {
    serverQuestionQueue: [], //後端題目隊列-for題目設定用
    tempQuestionQueue: [], // 前端題目隊列-for題目設定用
    questionList: [], //問題列表-for檢視問卷用
    changeOrder: false,
  },
  getters: {
    getServerQuestionQueue: (state) => state.serverQuestionQueue,
    getTempQuestionQueue: (state) => state.tempQuestionQueue,
    getDoneQuestionList: (state) => state.questionList,
    getChangeOrder: (state) => state.changeOrder,
  },
  mutations: {
    //問卷題目的List
    SET_ORDER(state, questions) {
      state.serverQuestionQueue = questions; //後端傳回來的題目list
    },
    SET_NEW_ORDER(state, questions) {
      state.tempQuestionQueue = questions; //前端多加參數的題目list(key,class,sort,question_type,questionnaire_id)
    },
    SET_DONE_ORDER(state, questions) {
      state.questionList = questions; //檢視問卷用的題目list
    },

    //新增題組或題目(第一層)
    SET_NEW_QUESTION(state, question) {
      state.serverQuestionQueue.push(question);
    },

    //新增題組內的題目(第二層)
    SET_GROUP_QUESTION(state, question) {
      const index = state.tempQuestionQueue.findIndex(
        (e) => e.question_type === 2 && e.group_id === question.groupId
      );
      const copyOne = [...state.tempQuestionQueue];
      copyOne[index].question_array.push(question);
      state.serverQuestionQueue = copyOne;
    },

    //編輯題組資訊
    UPDATE_GROUP_INFO(state, groupInfo) {
      const index = state.tempQuestionQueue.findIndex(
        (e) => e.question_type === 2 && e.group_id === groupInfo.group_id
      );
      const copyOne = [...state.tempQuestionQueue];
      copyOne[index]['name'] = groupInfo.name;
      copyOne[index]['bg_color'] = groupInfo.bg_color;
      copyOne[index]['description'] = groupInfo.description;
      copyOne[index]['update_datetime'] = groupInfo.update_datetime;
      state.serverQuestionQueue = copyOne;
    },

    //編輯題目(第一層)
    UPDATE_QUESTION(state, question) {
      const index = state.tempQuestionQueue.findIndex(
        (e) => e.id === question.id
      );
      const copyOne = [...state.tempQuestionQueue];
      copyOne.splice(index, 1, question);
      state.serverQuestionQueue = copyOne;
    },

    //編輯題組內題目(第二層)
    UPDATE_GROUP_QUESTION(state, question) {
      const group_index = state.tempQuestionQueue.findIndex(
        (e) => e.question_type === 2 && e.group_id === question.groupId
      );
      const copyOne = [...state.tempQuestionQueue];
      copyOne[group_index]['question_array'].forEach((e, index) => {
        if (e.id === question.id) {
          copyOne[group_index]['question_array'].splice(index, 1, question);
        }
      });
      state.serverQuestionQueue = copyOne;
    },

    //刪除題組
    REMOVE_GROUP(state, groupIndex) {
      const copyOne = [...state.tempQuestionQueue];
      copyOne.splice(groupIndex, 1);
      state.serverQuestionQueue = copyOne;
    },

    //刪除題目
    REMOVE_QUESTION(state, question) {
      const copyOne = [...state.tempQuestionQueue];
      if (question.isChild) {
        copyOne.forEach((ques) => {
          if (ques.group_id === question.groupId) {
            ques.question_array.splice(question.nestedIndex, 1);
          }
        });
      } else {
        copyOne.splice(question.nestedIndex, 1);
      }

      state.serverQuestionQueue = copyOne;
    },

    //清空所有題目List
    CLEAR_QUES(state) {
      state.serverQuestionQueue = [];
      state.tempQuestionQueue = [];
      state.questionList = [];
    },

    //改變題目順序
    SET_CHANGE_ORDER(state, status) {
      state.changeOrder = status;
    },
  },
  actions: {
    // 儲存題目排序
    async saveAllChange({ commit }, question_array) {
      try {
        await doSaveAllQuestions(question_array);
        return Promise.resolve();
      } catch (error) {
        Message.error(error.message);
        return Promise.reject();
      }
    },

    // for編輯問卷用：取得問卷的題目列表，list不包含選項
    async fetchQuestions({ commit }, id) {
      try {
        const { data } = await getQuestionList(id);
        commit('SET_ORDER', data);
      } catch (error) {
        Message.error(error.message);
      }
    },

    //新增題組
    async createGroup({ commit }, group) {
      try {
        const { id, group_id } = await doSaveGroup(group);
        commit('SET_NEW_QUESTION', { ...group, id, group_id });
        Message.success('新增題組成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //編輯題組
    async updateGroup({ commit }, group) {
      try {
        await doSaveGroup(group);
        commit('UPDATE_GROUP_INFO', group);
        Message.success('儲存成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //新增題目
    async createQuestion({ commit }, question) {
      try {
        const { id } = await doSaveSingleQuestion(question);
        commit('SET_NEW_QUESTION', { ...question, id });
        Message.success('新增題目成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //新增群組內題目
    async createGroupQuestion({ commit }, question) {
      try {
        const { id } = await doSaveSingleQuestion(question);
        commit('SET_GROUP_QUESTION', { ...question, id });
        Message.success('新增題目成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //編輯題目
    async updateQuestion({ commit }, question) {
      try {
        await doSaveSingleQuestion(question);
        commit('UPDATE_QUESTION', question);
        Message.success('儲存成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //編輯群組內題目
    async updateGroupQuestion({ commit }, question) {
      try {
        await doSaveSingleQuestion(question);
        commit('UPDATE_GROUP_QUESTION', question);
        Message.success('儲存成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //刪除群組
    async deleteGroup({ commit }, group) {
      try {
        const res = await doDeleteGroup(group.group_id);
        commit('REMOVE_GROUP', group.nestedIndex);
        Message.success('刪除題組成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //刪除題目
    async deleteQuestion({ commit }, question) {
      try {
        const res = await doDeleteQuestion(question.id);
        commit('REMOVE_QUESTION', question);
        Message.success('刪除題目成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //複製題組
    async cloneGroup({ commit }, group) {
      try {
        const res = await doCloneGroup(group);
        commit('SET_NEW_QUESTION', res);
        Message.success('複製題組成功');
      } catch (error) {
        Message.error(error.message);
      }
    },

    //複製題目
    async cloneQuestion({ commit }, question) {
      try {
        const res = await doCloneQuestion(question);
        if (res.groupId.length > 0) {
          commit('SET_GROUP_QUESTION', res);
        } else {
          commit('SET_NEW_QUESTION', res);
        }
        Message.success('複製題目成功');
      } catch (error) {
        Message.error(error.message);
      }
    },
    // for檢視問卷用：取得問卷的題目列表，list包含選項
    async fetchAllQuestions({ commit }, id) {
      try {
        const { data } = await getAllQuestionList(id);
        commit('SET_DONE_ORDER', data);
      } catch (error) {
        Message.error(error.message);
      }
    },
  },
};
